import DrugInfo from './DrugInfo';
import React from 'react';

const GelsynInfo = () => {
  return (
    <DrugInfo
      Ul={
        () => {
          return (
            <ul style={{ fontSize: '.9rem'}}>
              <li>This offer is valid for a one-time rebate of up to $150 off your co-pay or out-of-pocket expense for one (1) syringe of GELSYN-3 injected per knee and is not valid with any other product. Eligible patients are limited to one (1) rebate per syringe of GELSYN-3.</li>
              <li>This offer is only valid for patients with commercial insurance. Patients without commercial insurance are not eligible. This offer is not valid for any person eligible for reimbursement of prescriptions, in whole or in part, by any federal, state, or other governmental programs, including, but not limited to, Medicare (including a Medicare Part D or Medicare Advantage plan), Medicaid, TRICARE, Veterans Administration or Department of Defense health coverage, CHAMPUS, the Puerto Rico Government Health Insurance Plan, or any other federal or state health care programs or pharmaceutical assistance program.</li>
              <li>Patient must be a U.S. resident.</li>
              <li>Patient is responsible for reporting receipt of co-pay assistance to any insurer, health plan, or other third party who pays for or reimburses any part of the GELSYN-3 prescription, as may be required by insurer or plan.</li>
              <li>Only original rebate offers will be accepted. This rebate is not valid if reproduced, reprinted or photocopied.</li>
              <li>This offer is nontransferable and may not be combined with any other offer.</li>
              <li>This program is not health insurance.</li>
              <li>Bioventus LLC reserves the right to rescind, revoke or amend this offer without notice.</li>
              <li>This offer is only good for GELSYN-3 purchased in the USA before December 31, 2023. Void where prohibited by law. Offer valid only for requests postmarked before March 31, 2024.</li>
            </ul>
          );
        }
      }
      Summary={
        () => {
          return (
            <div style={{ fontSize: '.9rem'}}>
              <b>Summary of Indications for Use:</b> GELSYN-3 is indicated for the treatment of pain in osteoarthritis (OA) of the knee in patients who have failed to respond adequately to conservative non-pharmacologic therapy and simple analgesics (e.g., acetaminophen). Do not administer to patients with known hypersensitivity (allergy) to sodium hyaluronate preparations. Do not inject GELSYN-3 into the knees of patients having knee joint infections or skin diseases or infections in the area of the injection site. GELSYN-3 is not approved for pregnant or nursing women, or children. Risks can include general knee pain, warmth and redness or pain at the injection site. Full prescribing information can be found in product labeling, at www.GELSYN3.com or by calling Customer Service at 1-800-836-4080.
            </div>
          );
        }
      }
    />
  );
};

export default GelsynInfo;

import React, { FC } from 'react';

const DrugInfo: FC<{ Ul: FC, Summary: FC }> = ({ Ul, Summary }) => {
  return (
    <>
      <div className='row m-0'>
        <div className='col-12 p-2' style={{ backgroundColor: '#efefef' }}>
          <h3>Eligibility Criteria, Terms and Conditions:</h3>
          <Ul />
        </div>
      </div>
      <div className='row m-0'>
        <div className='col-12 p-2' style={{ backgroundColor: '#3e1052', color: 'white' }}>
          <Summary />
        </div>
      </div>
    </>
  );
};

export default DrugInfo;

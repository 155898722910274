import React, { ChangeEvent, Children, cloneElement, FC, ReactElement, useState } from 'react';
import Drug from './types/DrugType';
import logo from './logo.png'
import ins_footer from './ins_footer.png'
import rebate_footer from './rebate_footer.png'
import DurolaneInfo from './DurolaneInfo';
import GelsynInfo from './GelsynInfo';

type State = {
  'drug': Drug,
  'files': string[],
  'patient-fullname': string,
  'patient-address-street': string,
  'patient-address-city': string,
  'patient-address-state': string,
  'patient-address-zip': string,
  'patient-phone_number-number': string,
  'doctor-fullname': string,
  'practice-address-street': string,
  'practice-address-city': string,
  'practice-address-state': string,
  'practice-address-zip': string,
  'federal_reimbursement_ineligible': boolean,
  'not_ma_resident': boolean
};

const STATES = ['AK','AL','AR','AZ','CA','CO','CT','DC','DE','FL','FM','GA',
      'HI','IA','ID','IL','IN','KS','KY','LA','MA','MD','ME','MI','MN','MO',
      'MS','MT','NC','ND','NE','NH','NJ','NM','NV','NY','OH','OK','OR','PA',
      'PR','RI','SC','SD','TN','TX','UT','VA','VI','VT','WA','WI','WV','WY']

const Input: FC<{
  className?: string;
  id: keyof State;
  label: string;
  formControl?: boolean;
  labelPlacement?: 'before' | 'after';
  state: State;
  setState: (state: State) => void;
  labelClassName?: string;
}> =
  ({
    className: initClassName = '',
    children,
    id,
    label,
    formControl = true,
    labelPlacement = 'before',
    state,
    setState,
    labelClassName = '',
    ...props
  }) => {
    const className = (formControl ? 'form-control ' : '') + initClassName;
    const value = state[id];
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      setState({
        ...state,
        [id]: e.target.value,
      })
    }

    return (
      <div>
        {labelPlacement === 'before' && <label className={labelClassName} htmlFor={id}>{label}</label>}
        {Children.map((children), (child) => {
          const type = (child as ReactElement)?.props?.type;
          const onChangeProps =
            {} as { onChange?: (e: ChangeEvent<HTMLInputElement>) => void };

          if (type === 'checkbox') {
            (props as { checked?: boolean }).checked = Boolean(value);

            onChangeProps.onChange = (e: ChangeEvent<HTMLInputElement>) => {
              setState({
                ...state,
                [id]: e.target.checked,
              })
            }
          }

          if (type === 'file') {
            onChangeProps.onChange = (e: ChangeEvent<HTMLInputElement>) => {
              setState({
                ...state,
                [id]: e.target.files,
              })
            }
          }

          const required = id !== 'files' && type !== 'checkbox' ? true : false;

          const valueProps = {} as { value?: string | undefined | string[] };

          if (type !== 'file') valueProps.value = String(value);


          return (
            cloneElement(
              child as ReactElement,
              {
                ...props,
                className,
                id,
                onChange,
                required,
                ...onChangeProps,
                ...valueProps,
              }
            )
          );
        })}
        {labelPlacement === 'after' && <label className={labelClassName} htmlFor={id}>{label}</label>}
      </div>
    );
  };

const initialState = {
  'drug': '' as Drug,
  'files': [],
  'patient-fullname': '',
  'patient-address-street': '',
  'patient-address-city': '',
  'patient-address-state': '',
  'patient-address-zip': '',
  'patient-phone_number-number': '',
  'doctor-fullname': '',
  'practice-address-street': '',
  'practice-address-city': '',
  'practice-address-state': '',
  'practice-address-zip': '',
  'federal_reimbursement_ineligible': false,
  'not_ma_resident': false
};

function App() {
  const [state, setState] = useState<State>(initialState);
  const [message, setMessage] = useState<String | null>(null);

  const handleOnSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();

    Object.entries(state).forEach(([key, value]) => {
      if (key === 'files') {
        for (let i = 0; i < state.files.length; i++) {
          formData.append('files', state.files[i]);
        }
      } else {
        formData.append(key, String(value));
      }
    });

    const resp = await fetch(`${process.env.REACT_APP_SERVER_BASE_DOMAIN}/api/submit`, {
      method: 'POST',
      body: formData
    })

    if (resp.ok) {
      document?.scrollingElement?.scrollTo(0, 0);

      setState(initialState);
      setMessage('Successfully Uploaded')

      setTimeout(() => {
        setMessage(null);
      }, 5000);
    } else {
      alert('there was an error')
    }
  };

  const stateProps = { state, setState };

  return (
    <div className='container'>
      {message && (
        <div className='alert alert-primary' role='alert'>
          {message}
        </div>
      )}
      <div className='card mt-2'>
        <div className='card-body p-0'>
          <div className='row m-0'>
            <div className='col-md-6 p-0' style={{ backgroundImage: `url(${rebate_footer})`, backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat'}}>
	      <div style={{backgroundColor: '#007278', color: 'white', marginRight: '2rem'}}>
                <div className='px-2 pb-2' style={{ backgroundImage: `url(${ins_footer})`, backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat'}}>
		  <h2>
                    Please complete all the fields below and
		    include your EOB to receive your rebate.
                  </h2>
		</div>
	      </div>
              <form className='px-2' onSubmit={handleOnSubmit}>
                <div className='form-group'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <Input
                        id='drug'
                        label='Drug'
                        {...stateProps}
                        className='form-select'
                      >
                      <select>
                        <option value='' />
                        <option value='durolane'>Durolane</ option>
                        <option value='gelsyn'>Gelsyn</ option>
                      </select>
                    </Input>
		  </div>
		</div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Input id='files' label='EOB PDF(s)' {...stateProps}>
                      <input type="file" multiple />
                    </Input>
		  </div>
		</div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Input
                      id='patient-fullname'
                      label='Patient Full Name'
                      {...stateProps}
                    >
                      <input />
                    </Input>
		  </div>
		</div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Input
                      id='patient-address-street'
                      label='Street'
                      {...stateProps}
                    >
                      <input />
                    </Input>
		  </div>
		</div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Input
                      id='patient-address-city'
                      label='City'
                      {...stateProps}
                    >
                      <input />
                    </Input>
		  </div>
                  <div className='col-md-2'>
                    <Input
                      id='patient-address-state'
                      label='State'
                      {...stateProps}
		      className='form-select'
                    >
                      <select>
                        <option value='' />
			{ STATES.map((prefix) => { return <option value={prefix} key={prefix}>{prefix}</option>  } ) }
                      </select>
                    </Input>
		  </div>
                  <div className='col-md-4'>
                    <Input id='patient-address-zip' label='Zip' {...stateProps} >
                      <input />
                    </Input>
		  </div>
		</div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Input
                      id='patient-phone_number-number'
                      label='Phone'
                      {...stateProps}
                    >
                      <input />
                    </Input>
		  </div>
		</div>
                <div className='row'>
                  <div className='col-md-12'>
                  <Input
                    id='doctor-fullname'
                    label='Doctor Full Name'
                    {...stateProps}
                  >
                    <input />
                  </Input>
		  </div>
		</div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Input
                      id='practice-address-street'
                      label='Practice Street'
                      {...stateProps}
                    >
                      <input />
                    </Input>
		  </div>
		</div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Input
                      id='practice-address-city'
                      label='City'
                      {...stateProps}
                    >
                      <input />
                    </Input>
		  </div>
                  <div className='col-md-2'>
                    <Input
                      id='practice-address-state'
                      label='State'
                      {...stateProps}
		      className='form-select'
                    >
                      <select>
                        <option value='' />
			{ STATES.map((prefix) => { return <option value={prefix} key={prefix}>{prefix}</option>  } ) }
                      </select>
                    </Input>
		  </div>
                  <div className='col-md-4'>
                    <Input id='practice-address-zip' label='Zip' {...stateProps} >
                      <input />
                    </Input>
		  </div>
		</div>
                <div className='row'>
                  <div className='col-md-12'>
                      <div className='form-check'>
                    <Input
                      id='federal_reimbursement_ineligible'
                      label='I am not eligible to receive reimbursement from any
                      federal, state, or other governmental programs'
                      formControl={false}
                      labelPlacement='after' {...stateProps}
	              className='form-check-input'
	              labelClassName='form-check-label'
                    >
                        <input type='checkbox' />
                    </Input>
                      </div>
		  </div>
		</div>
                <div className='row'>
                  <div className='col-md-12'>
                      <div className='form-check'>
                    <Input
                      id='not_ma_resident'
                      label='I am not a resident of Massachusetts.'
                      formControl={false}
                      labelPlacement='after' {...stateProps}
	              className='form-check-input'
	              labelClassName='form-check-label'
                    >
                        <input type='checkbox' />
                    </Input>
                      </div>
                  </div>
                </div>
              </div>
	      <div className='pb-2'>
                <button type='submit' className="btn btn-primary">Submit</button>
              </div>
              </form>
            </div>
            <div className='col-md-6 p-0' style={{ backgroundColor: '#3e1052' }}>
              {
                ['durolane', 'gelsyn'].includes(state.drug) ? (
                  state.drug === 'durolane' ? <DurolaneInfo /> : <GelsynInfo />) :
                  <div className='row m-0'>
		    <div className='col-md-12 p-2' style={{ fontSize: '.9rem', backgroundColor: 'rgb(239, 239, 239)' }}>
		    <h3>Eligibility Criteria, Terms and Conditions:</h3><br />
                      <h1 className='text-center'>
                        Please Select a drug to see Eligibility Criteria, Terms and Conditions
                      </h1>
                    </div>
                  </div>

              }
 	      <div className='row m-0' style={{ backgroundColor: '#3e1052', color: 'white', fontSize: '.85rem'}}>
	        <br />
	        <br />
                <div className='col-lg-7 p-2'>
                  © 2023 Bioventus, the Bioventus logo, DUROLANE, and GELSYN-3 are registered trademarks of Bioventus LLC.
                </div>
                <div className='col-lg-5 p-2'>
		 <div className='p-1'>
                  <img src={logo}  style={{ float: 'right' }}></img>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
	<div className='card-footer' style={{ fontSize: '0.8rem', backgroundColor: '#3e1052', borderTop: '0px'}}>
        <br /> 
	</div>

      </div>
    </div>
  );
}

export default App;
